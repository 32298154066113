import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql, PageProps } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { Family, Model, Route } from "../global"
// import {
//   Family,
//   Route,
// } from "../components/molecules/FamilySeriesTabs/FamilySeriesTabs.d"
import { Gallery, ToyotaCare } from "../components/organisms/Series"
import { Image } from "../components/atoms/Image"
import { Tab, Tabs } from "../components/atoms/Tabs"
import AccyBlocks from "../components/organisms/Accessories/Blocks"
import GenericHero from "../components/organisms/Accessory/Hero/GenericHero"
import { Link } from "../components/atoms/Link"
import { SEO } from "../components/atoms/SEO"
import FAQ from "../components/molecules/FAQ/FAQ"
import { LanguageContext } from "../contexts/Language"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import Icon from "../components/atoms/Icon"
import FamilySeries from "../components/organisms/Accessories/FamilySeries"
import { normalizeSeriesNameForLink } from "../helpers"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import CtaBox from "../components/molecules/Heros/CtaBox"
import HybridFeatures from "../components/organisms/Series/HybridFeatures/HybridFeatures"
import PartInfo from "../components/organisms/Part/Info"
import PartHighlights from "../components/organisms/Part/Highlights"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const AccessoriesPage: React.FC<PageProps> = ({ data, location }: any) => {
  // GraphQL data
  const { page } = data
  const familyData = page.vehicleFamilies.familyRef
    .filter((family: Family) => family.isActive === true)
    .map((family: Family) => {
      if (!family) return

      return {
        family: family.name,
        data: family.routes?.map(({ seriesImage, route }: Route) => {
          const vehicle = route?.page?.vehicle
          let slug = route?.slug?.current?.replaceAll("-", "")

          let modelData = vehicle?.models?.find(
            (model: Model) =>
              model.hasOwnProperty("featuredModel") &&
              model.featuredModel === true
          )?.model

          if (modelData === undefined) {
            if (vehicle?.models && vehicle?.models[0]?.models) {
              // this means we are dealing with a series that exclusively has models in a group
              modelData = vehicle?.models[0]?.models[0]?.model // TODO clean this up a bit but will work for current use case
            } else {
              return
            }
          }

          return {
            image: seriesImage,
            name: modelData?.series.name,
            msrp: modelData?.series.msrp,
            mpgCity: modelData?.series.mpgCity,
            mpgHighway: modelData?.series.mpgHighway,
            slug: slug,
          }
        }),
      }
    })

  const [activeFamily, setActiveFamily] = useState(0)

  // Tealium
  const { handleTealView } = useTealiumView()

  useEffect(() => {
    handleTealView({
      page_type: "accessories",
      tealium_event: "accessories_home",
      page_name: "accessories",
    })
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <FamilySeries series={null} />
      <SanitySections
        data={page}
        activeFamily={activeFamily}
        setActiveFamily={setActiveFamily}
        families={familyData}
      />
    </Layout>
  )
}

const SanitySections = ({
  data,
  activeFamily,
  setActiveFamily,
  families,
}: any): JSX.Element => {
  const { _ } = useContext(LanguageContext)
  // const [isExpanded, setisExpanded] = useState(true)
  // const content = useRef(null)
  console.log(data)
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "genericHero":
            return (
              <GenericHero
                {...section}
                showGradient={false}
                fromAccessory={true}
              />
            )
          case "hybridFeatures":
            return <HybridFeatures {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
          case "partsAndServiceHero":
            return <PartAndServiceHero {...section} />
          case "gallery":
            return <Gallery {...section} />
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "imageBlocks":
            return <AccyBlocks accyBlocks={section} />
          case "faqs":
            return <FAQ {...section} title={data.title} />
          case "imageBlocksWithDescriptionSection":
            return (
              <ImageBlocksWithDescription
                section={section}
                fromAccessory={true}
              />
            )
        }
      })}
    </>
  )
}

export const query = graphql`
  query AccessoryPageQuery($id: String) {
    page: sanityPageAccessories(id: { eq: $id }) {
      title
      language
      id
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...GenericHeroData
        ...ImageBlocksDataWithMedia
        ...GallerySection
        ...ToyotaCareData
        ...FAQData
        ...ImageBlocksWithDescriptionData
        ...PartsAndServiceHeroData
        ...CallToActionBoxData
        ...HybridFeaturesData
        ...TabbedImageModuleData
        ...PartInfoData
      }
      vehicleFamilies {
        familyRef {
          id
          name
          isActive
          routes {
            seriesImage {
              ...SanityImageData
            }
            route {
              slug {
                current
              }
              page {
                ... on SanityPageSeries {
                  title
                  vehicle {
                    models {
                      ... on SanityVehicleModel {
                        featuredModel
                        model {
                          ... on SanityModel {
                            _key
                            series {
                              name
                              msrp
                              mpgCity
                              mpgHighway
                            }
                          }
                          ... on SanityCustomModel {
                            _key
                            series {
                              name
                              msrp
                              mpgCity
                              mpgHighway
                            }
                          }
                        }
                      }
                      ... on SanityVehicleModelGroup {
                        _key
                        _type
                        models {
                          _key
                          featuredModel
                          model {
                            ... on SanityModel {
                              _id
                              series {
                                name
                                msrp
                                mpgCity
                                mpgHighway
                              }
                            }
                            ... on SanityCustomModel {
                              _id
                              series {
                                name
                                msrp
                                mpgCity
                                mpgHighway
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AccessoriesPage
